//Require CSS
require('../sass/branding/_fonts.sass')

//require('../css/style.css'); //vendor code but chucks better if required here
//Hubert: to be removed with the rest of CSS (and maybe LESS) files, requires investigating what has to stay

require('../css/bootstrap.css');

// require('../css/unhide.css')

//Require SASS
require('../sass/child.sass');
require('../sass/blog.sass');
require('../sass/modules/modules.sass');
require('../sass/blocks/blocks.sass');

//Reqiure Slick styles (change to npm folder)
require('./slick/slick.css'); //vendor code but chucks better if required here
require('./slick/slick-theme.css'); //vendor code but chucks better if required here

//Require LESS
// require('../global-assets/less/global.less')


//Require jqury-webui-popover
require('../css/webui-popover.css');
